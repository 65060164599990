<div class="layout-wrapper" [ngClass]="containerClass">
    <app-top-bar></app-top-bar>
    <div class="layout-sidebar" style="border-radius:0 !important; top: 5rem !important;left: 0 !important;height: 100%;">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container" style="padding-left: 2rem !important">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <!--<app-footer></app-footer>-->
    </div>
    <div class="layout-mask"></div>
</div>