import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
import { Communication } from '../model/communication';
import { Testigo } from '../model/testigo';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_URL = environment.apiURL

  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any>{
    return this.http.post(`${this.API_URL}/login.php`, {
      username: username,
      password: password
    });
  }

  getAllUsers(): Observable<any> {
    return this.http.get(`${this.API_URL}/getAllUsers.php`);
  }

  getInforUser(email: string): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoUser.php`, {
      email: email,
    });
  }

  getInfoCommunication(pin: string, pin_code: string): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoCommunication.php`, {
      pin: pin,
      pin_code: pin_code
    });
  }

  getInfoCommunicationById(id: number, usuario: any): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoCommunicationById.php`, {
      id: id,
      usuario: usuario
    });
  }

  getInfoCommunicationByUser(usuario: string): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoCommunicationByUser.php`, {
      usuario: usuario,
    });
  }

  getAllCommunication(): Observable<any> {
    return this.http.get(`${this.API_URL}/getAllCommunication.php`);
  }

  updateCommunication(id_comunicacion: number, id_fase: number, usuario: string): Observable<any> {
    return this.http.post(`${this.API_URL}/editCommunication.php`, {
      id_comunicacion: id_comunicacion,
      id_fase: id_fase,
      usuario: usuario,
    });
  }

  updateInformeCommunication(id_comunicacion: number, id_fase: number, informe: string): Observable<any> {
    return this.http.post(`${this.API_URL}/editInformeCommunication.php`, {
      id_comunicacion: id_comunicacion,
      id_fase: id_fase,
      informe: informe,
    });
  }

  getInfoChat(id_comunicacion: number): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoChat.php`, {
      id_comunicacion: id_comunicacion,
    });
  }

  getInfoTestigos(id_comunicacion: number): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoTestigos.php`, {
      id_comunicacion: id_comunicacion,
    });
  }

  getInfoArchivos(id_comunicacion: number): Observable<any> {
    return this.http.post(`${this.API_URL}/getInfoArchivos.php`, {
      id_comunicacion: id_comunicacion,
    });
  }

  addChat(id_comunicacion: number, usuario: string, descripcion: string): Observable<any> {
    return this.http.post(`${this.API_URL}/addChat.php`, {
      id_comunicacion: id_comunicacion,
      usuario: usuario,
      descripcion: descripcion
    });
  }

  addTestigo(testigo: Testigo): Observable<any> {
    return this.http.post(`${this.API_URL}/addTestigo.php`, {
      id_comunicacion: testigo.id_comunicacion,
      nombre: testigo.nombre,
      apellidos: testigo.apellidos,
      email: testigo.email,
      telefono: testigo.telefono,
      descripcion: testigo.descripcion
    });
  }

  editUser(user: User): Observable<any>{
    return this.http.post(`${this.API_URL}/editUser.php`, {
      id: user.id,
      email: user.email,
      nombre: user.nombre,
      apellidos: user.apellidos,
      rol_admin: user.rol_admin,
      rol_receptor: user.rol_receptor,
      rol_compliance: user.rol_compliance
    });
  }

  deleteUser(email: string): Observable<any> {
    return this.http.post(`${this.API_URL}/deleteUser.php`, {
      email: email,
    });
  }

  sendEmail(title: string, message: string,  email: string): Observable<any>{
    return this.http.post(`${this.API_URL}/sendEmail.php`, {
      title: title,
      message: message,
      email: email,
    });
  }

  createCommunication(communication: Communication, testigo: Testigo): Observable<any>{
    return this.http.post(`${this.API_URL}/createCommunication.php`, {
      denunciante_nombre: communication.denunciante_nombre,
      denunciante_apellidos: communication.denunciante_apellidos,
      denunciante_dni: communication.denunciante_dni,
      denunciante_email: communication.denunciante_email,
      denunciante_telefono: communication.denunciante_telefono,
      id_categoria: communication.id_categoria,
      id_relacion: communication.id_relacion,
      id_comunicacion: 0,
      id_fase: 0,
      denuncia_descripcion: communication.denuncia_descripcion,
      comentario: communication.comentario,
      usuario: '',
      fecha_incidente: communication.fecha_incidente,
      fecha_inicio: '',
      fecha_fin: '',
      nombre_testigo: testigo.nombre,
      apellidos_testigo: testigo.apellidos,
      email_testigo: testigo.email,
      telefono_testigo: testigo.telefono,
      descripcion_testigo: testigo.descripcion
    });
  }

  upload(id_comunicacion: number, usuario: string, descripcion: string): Observable<any>{
    return this.http.post(`${this.API_URL}/upload.php`, {
      id_comunicacion: id_comunicacion,
      usuario: usuario,
      descripcion: descripcion,
    });
  }
}
