import { Pipe, PipeTransform } from '@angular/core';
import CommonUtils from 'src/app/core/utils/common-utils';

@Pipe({
  name: 'phase'
})
export class PhasePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    let phase: string = '';
    const phases = CommonUtils.getPhase();
    phases.forEach((element: { id: number; name: any; }) => {
      if (element.id === Number(value)){
        phase = element.name;
      }
    });
    return phase;
  }

}
