import { Pipe, PipeTransform } from '@angular/core';
import CommonUtils from '../../core/utils/common-utils';

@Pipe({
  name: 'categories'
})
export class CategoriesPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let category: string = '';
    const categories = CommonUtils.getCategory();
    categories.forEach((element: { id: number; name: any; }) => {
      if (element.id === Number(value)){
        category = element.name;
      }
    });
    return category;
  }

}
