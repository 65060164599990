import { Pipe, PipeTransform } from '@angular/core';
import CommonUtils from '../../core/utils/common-utils';

@Pipe({
  name: 'relations'
})
export class RelationsPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    let relation: string = '';
    const relations = CommonUtils.getRelacion();
    relations.forEach((element: { id: number; name: any; }) => {
      if (element.id === Number(value)){
        relation = element.name;
      }
    });
    return relation;
  }

}
