import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, createUrlTreeFromSnapshot } from '@angular/router';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userService.isLoggedIn()){
      return true
    }else{
      localStorage.clear();
      this.router.navigate(['/']);
      return this.userService.isLoggedIn() ? true : createUrlTreeFromSnapshot(next, ['/dashboard', '/']);
    }
    
  }
}
