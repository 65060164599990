import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from './core/services/auth-guard.service';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot([
        { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
        { path: 'landing', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
        { path: 'emitir-comunicacion', loadChildren: () => import('./features/emitir-comunicacion/emitir-comunicacion.module').then(m => m.EmitirComunicacionModule) },
        { path: 'consultar-comunicacion', loadChildren: () => import('./features/consultar-comunicacion/consultar-comunicacion.module').then(m => m.ConsultarComunicacionModule) },
        { path: 'identificador-comunicacion', loadChildren: () => import('./features/identificador-comunicacion/identificador-comunicacion.module').then(m => m.IdentificadorComunicacionModule) },
        { path: 'informacion', loadChildren: () => import('./features/informacion/informacion.module').then(m => m.InformacionModule) },
        { path: 'proteccion-datos', loadChildren: () => import('./features/proteccion-datos/proteccion-datos.module').then(m => m.ProteccionDatosModule) },
        { path: '', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
        {
            path: 'dashboard', component: LayoutComponent,
            canActivate: [AuthGuardService],
            children: [
                { path: '', canActivate: [AuthGuardService], loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule) },
                { path: 'perfil', canActivate: [AuthGuardService], loadChildren: () => import('./features/perfil/perfil.module').then(m => m.PerfilModule) },
                { path: 'comunicaciones', canActivate: [AuthGuardService], loadChildren: () => import('./features/comunicaciones/comunicaciones.module').then(m => m.ComunicacionesModule) },
                { path: 'comunicacion/:id', canActivate: [AuthGuardService],loadChildren: () => import('./features/comunicacion/comunicacion.module').then(m => m.ComunicacionModule) },
                { path: 'usuarios', canActivate: [AuthGuardService], loadChildren: () => import('./features/usuarios/usuarios.module').then(m => m.UsuariosModule) },
                { path: 'tipo-comunicacion', canActivate: [AuthGuardService], loadChildren: () => import('./features/tipo-comunicacion/tipo-comunicacion.module').then(m => m.TipoComunicacionModule) },
                { path: 'tipo-relacion', canActivate: [AuthGuardService], loadChildren: () => import('./features/tipo-relacion/tipo-relacion.module').then(m => m.TipoRelacionModule) },
            ]
        },
       // { path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },
        
        
     //   { path: 'notfound', component: NotfoundComponent },
        { path: '**', redirectTo: '/notfound' },
    ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
