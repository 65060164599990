
export default class CommonUtils{

    static booleanify(value: any): boolean {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]
      
        return truthy.includes(value)
    }

    static getCategory(): any {
        return    [
            { id: 1, name: 'Acoso', description: 'Acoso de cualquier tipo (sexual, laboral-mobbing, etc.), abuso de poder, favoritismo, amenazas, discriminación y malos tratos al personal.' },
            { id: 2, name: 'Financiera, contable y legal', description: 'Falsificación o modificación ilegal de información contable, operativa y financiera, incumplmiento de normativa obligatoria y elusión de controles internos de la empresa.' },
            { id: 3, name: 'Corrupción', description: 'Corrupción y acuerdo con proveedores/clientes.' },
            { id: 4, name: 'Daño a los bienes', description: 'Daño o utilización inapropiada de los bienes y/o servicios de la empresa.' },
            { id: 5, name: 'Fraude / Robo', description: 'Fraude económico, robo de mercancía o bienes, blanqueo de capitales, gastos y compras sin autorización, etc.' },
            { id: 6, name: 'Abuso de poder', description: 'Abuso de poder, favoritismo, amenazas, mal comportamiento,' },
            { id: 7, name: 'Información confidencial', description: 'Robo o sustracción de información interna o confidencial de la empresa. Violación de secretos empresariales.' },
            { id: 8, name: 'Incumplimiento código ético', description: 'Cualquier información sobre incumplimiento del código ético de la empresa.' },
        ];
    }

    static getRelacion(): any {
        return [
            { id: 1, name: 'Cliente'},
            { id: 2, name: 'Proveedor'},
            { id: 3, name: 'Persona empleada'},
            { id: 4, name: 'Competencia' },
            { id: 5, name: 'Accionista' },
            { id: 6, name: 'Otros / No desea identificarlo' }
        ];
    }

    static getPhase(): any {
        return [
            { id: 0, name: 'Pendiente'},
            { id: 1, name: 'Validada'},
            { id: 2, name: 'Compliance'},
            { id: 10, name: 'Rechazada' },
            { id: 11, name: 'Finalizada' },
            { id: 12, name: 'Finalizada - Rechazada' },
        ];
    }

}
