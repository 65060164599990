import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { LandingMenuComponent } from './components/landing-menu/landing-menu.component';
import { ButtonModule } from 'primeng/button';
import { RelationsPipe } from './pipes/relations.pipe';
import { CategoriesPipe } from './pipes/categories.pipe';
import { PhasePipe } from './pipes/phase.pipe';


@NgModule({
  declarations: [
    LandingMenuComponent,
    RelationsPipe,
    CategoriesPipe,
    PhasePipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    ButtonModule,
  ],
  exports: [
    LandingMenuComponent,
    RelationsPipe,
    CategoriesPipe,
    PhasePipe
  ]
})
export class SharedModule { }
