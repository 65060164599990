import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { ApiService } from 'src/app/core/services/api.service';
import { User } from 'src/app/core/model/user';
import CommonUtils from 'src/app/core/utils/common-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  model: any[] = [];

  constructor(public layoutService: LayoutService, private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    let admin = false;
    const email = localStorage.getItem('email');
    if (email){
        this.apiService.getInforUser(email).subscribe((data: User) => {
            if (data){
                if (CommonUtils.booleanify(data.rol_admin)){
                    admin = true;
                    this.model.push({
                        label: 'Configuración',
                        items: [
                            { label: 'Gestión de Usuarios', icon: 'pi pi-fw pi-user-edit', routerLink: ['/dashboard/usuarios'] },
                            { label: 'Tipo de Relación', icon: 'pi pi-fw pi-link', routerLink: ['/dashboard/tipo-relacion'] },
                            { label: 'Tipo de Comunicación', icon: 'pi pi-fw pi-share-alt', routerLink: ['/dashboard/tipo-comunicacion'] },
                        ]
                    })
                }
            }
            this.model.push({
                items: [
                    { 
                        label: 'Salir', 
                        icon: 'pi pi-fw pi-power-off', 
                        command: () => {
                            this.logout();
                        }  
                    },
                ]
            })
        });
    }
    
    this.model = [
        {
            label: 'Canal de Informante',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-th-large', routerLink: ['/dashboard'] },
                { label: 'Comunicaciones', icon: 'pi pi-fw pi-file-edit', routerLink: ['/dashboard/comunicaciones'] }
            ]
        },
    ];
  }


  logout(): void {
    localStorage.clear();
    this.router.navigate(['/']);
  }

}
