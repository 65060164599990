<div class="py-3 px-4 mx-0 lg: lg:px-8 flex align-items-center justify-content-between relative lg:static" style="background-color: white;">
    <a class="flex align-items-center" href="#">
        <img src="assets/img/logos/logo.png" alt="{{company}} Logo" height="50" class="mr-0 lg:mr-2">
    </a>
   
    <div class="align-items-center surface-0 flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:120px">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
            <li>
                <a (click)="router.navigate(['/'])" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
                   <span>Inicio </span>
                </a>
            </li>
            <li>
                <a (click)="router.navigate(['/emitir-comunicacion'])" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
                   <span>Emitir una comunicación </span>
                </a>
            </li>
            <li>
                <a (click)="router.navigate(['/consultar-comunicacion'])" pRipple class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3">
                    <span>Ver el estado de mi comunicación</span>
                </a>
            </li>
        </ul>
        <div *ngIf="showLogin" class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0 ml-5">
            <p-button (click)="router.navigate(['/login'])" label="Login" icon="pi pi-user"></p-button>
        </div>
    </div>
</div>