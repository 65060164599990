import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() {}

  isLoggedIn() {
    let isLoggedIn = false;
    if (localStorage.getItem('email')){
      isLoggedIn = true;
    }
    return isLoggedIn;
  }
}
